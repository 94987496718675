import RouteGenerator from "modules/core/lib/RouteGenerator";
import app from "modules/core/config/app";

const { routeNameList } = RouteGenerator.getInstance();

const list = [
    routeNameList.profile,
    routeNameList.clientLinkList,
    routeNameList.chat,
    routeNameList.clientLinkDetail,
    routeNameList.profileActivity,
    routeNameList.profileEdit,
    routeNameList.profileHistory,
    routeNameList.schedule,
    routeNameList.scheduleAppointment,
    routeNameList.specialistAdminForm,
    routeNameList.specialistAdminList,
    routeNameList.specialistAdminDelete,
    routeNameList.hospitalSessions,
    routeNameList.hospitalEvaluation,
    routeNameList.hospitalAdminUserList,
    routeNameList.hospitalAdminUserForm,
    routeNameList.hospitalUserDetails,
    routeNameList.hospitalUserForm,
    routeNameList.hospitalAdminUserDetails,
    routeNameList.hospitalUsers,
    routeNameList.hospitalSessionDetail,
    routeNameList.hospitalSessionDetailPrediagnostic,
    routeNameList.hospitalSessionDetailReport,
    routeNameList.hospitalEvaluation,
    routeNameList.hospitalSessionHistory,
    routeNameList.hospitalUserEdit,
    routeNameList.hospitalSessionBasicUser,
    routeNameList.awsSaas,
];

if (app.specialistDetailLoginRequired) {
    list.push(routeNameList.specialistDetail);
}

export default list;
